/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Career from '../../containers/career';
import CarouselHero from '../../containers/CarouselHero/CarouselHero';
import ContactArea from '../../containers/contact-area';
import Courses from '../../containers/courses';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import Methodology from '../../containers/methodology';
import TestimonialSection from '../../containers/testimonial-area';

const InPersonPage = ({ location }) => (
  <>
    <SEO title="Cursos Preparatórios para Concursos Públicos e Militares - Garra - Presencial" location={location} />
    <Header />
    <CarouselHero />
    <Career />
    <Courses />
    <TestimonialSection />
    <Methodology />
    <ContactArea />
    <Footer />
  </>
);

InPersonPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default InPersonPage;
