import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import SectionTitle from '../../components/ui/section-title';
import Text from '../../components/ui/Text';
import Anchor from '../../components/ui/Anchor';
import CaseStudyBox from '../../components/box-large-image/layout-two';
import SwiperSlider from '../../components/ui/swiper';
import { CaseStudyWrapper } from './courses.style';
import useSiteLocation from '../../hooks/useSiteLocation';

const CaseStudySection = ({
  sectionTitleStyle,
  bottomTextStyle,
  bottomTextLinkStyle,
  caseStudyStyles,
  slider,
  sliderStyle,
}) => {
  const coursesData = useStaticQuery(graphql`
    query CoursesQuery {
      indexJson(id: { eq: "courses-section" }) {
        id
        title
        subtitle
        bottom_text
        bottom_link_text_online
        bottom_link_text_in_person
        bottom_link_online
        bottom_link_in_person
        bgImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      allCoursesJson(filter: { is_featured: { eq: true } }) {
        edges {
          node {
            slug
            id
            title
            category
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 298, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `);

  const { isInPerson } = useSiteLocation();

  const generalInfo = coursesData.indexJson;
  const courses = coursesData.allCoursesJson.edges;
  const imageData = generalInfo.bgImage.childImageSharp.fluid;

  return (
    <CaseStudyWrapper fluid={imageData}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} title={generalInfo.title} subtitle={generalInfo.subtitle} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <SwiperSlider {...sliderStyle} settings={slider}>
              {courses.map(caseStudy => (
                <div className="item" key={caseStudy.node.slug}>
                  <CaseStudyBox
                    {...caseStudyStyles}
                    imageSrc={caseStudy.node.image.childImageSharp}
                    title={caseStudy.node.title}
                    category={caseStudy.node.category}
                    desc={caseStudy.node.excerpt}
                    path={`/${caseStudy.node.slug}`}
                    btnText="Saiba mais"
                  />
                </div>
              ))}
            </SwiperSlider>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Text {...bottomTextStyle}>
              {generalInfo.bottom_text}
              <Anchor
                {...bottomTextLinkStyle}
                path={isInPerson ? generalInfo.bottom_link_online : generalInfo.bottom_link_in_person}
              >
                {isInPerson ? generalInfo.bottom_link_text_online : generalInfo.bottom_link_text_in_person}
              </Anchor>
            </Text>
          </Col>
        </Row>
      </Container>
    </CaseStudyWrapper>
  );
};

CaseStudySection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  bottomTextStyle: PropTypes.object,
  bottomTextLinkStyle: PropTypes.object,
  caseStudyStyles: PropTypes.object,
  slider: PropTypes.object,
  sliderStyle: PropTypes.object,
};

CaseStudySection.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  bottomTextStyle: {
    fontSize: '18px',
    fontweight: 500,
    lineHeight: 1.4,
    color: '#333333',
    mt: '40px',
    align: 'center',
  },
  bottomTextLinkStyle: {
    fontWeight: 500,
    layout: 'underline',
    hover: {
      layout: 2,
    },
  },
  slider: {
    autoplay: true,
    slidesPerView: 3,
    speed: 2000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  },
  sliderStyle: {
    pagination: {
      bottom: 0,
    },
  },
  caseStudyStyles: {
    boxStyle: {
      mt: '20px',
      mb: '35px',
      ml: '15px',
      mr: '15px',
    },
    contentBoxStyle: {
      pt: '25px',
      pl: '26px',
      pr: '26px',
      textalign: 'left',
    },
    headingStyle: {
      as: 'h6',
      fontweight: 600,
      mb: '2px',
    },
    descStyle: {
      mb: 0,
      mt: '13px',
    },
  },
};

export default CaseStudySection;
